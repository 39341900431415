<template>
	<div>
		<!-- LP挖矿 -->
		<el-card class="box-card" shadow="never" style="margin-top: 20px;">
			<h3>（USDT-TIGER）LP {{$t('Mining')}}
			</h3>
			<div class="item">
				<div class="label">{{$t('LP_balance')}}</div>
				<div class="value">{{lp_banance|filtersUnitsPrice|toFloor}}</div>
			</div>
			<div class="item">
				<div class="label">{{$t('Staked')}}</div>
				<div class="value">{{LPTotal|filtersUnitsPrice|toFloor}}</div>
			</div>
			<div class="item">
				<div class="label">{{$t('Share')}}</div>
				<div class="value">{{lp_rate*100|toFloor(6)}}%</div>
			</div>
			<div class="item">
				<div class="label">{{$t('each_lp_value')}}</div>
				<div class="value">{{each_lp_value|toFloor(2)}} USDT</div>
			</div>
			<div class="item" style="margin-top: 20px;">
				<template v-if="hasReferrer">
					<template v-if="is_approve">
						<el-button size="medium" style="width:40%; margin: 0px auto;"
							@click="stakeDialogVisible = true,stake_num=''" type="primary">{{$t('stake')}}
						</el-button>
						<el-button size="medium" style="width:40%; margin: 0px auto;"
							@click="cancleDialogVisible = true,cancle_num=''" type="primary">{{$t('Withdraw')}}
						</el-button>
					</template>
					<template v-if="!is_approve">
						<el-button size="medium" @click="approve()" type="primary">{{$t('Approve')}}</el-button>
						<el-button type="info" size="medium" disabled>{{$t('stake')}}</el-button>
					</template>
				</template>
				<template v-if="!hasReferrer">
					<el-button size="medium" style="width:50%; margin: 0px auto;"
						@click="$message.error({message:'请先加入LP分紅',duration:4000})" type="primary">{{$t('stake')}}
					</el-button>
				</template>
			</div>
		</el-card>
		<!-- 抵押 -->
		<el-dialog :title="$t('Staked')" width="350px" :visible.sync="stakeDialogVisible">
			<h5 style="display: flex; align-items: center;justify-content: space-between;">TIGER/USDT(LP)
				<el-tooltip class="item" style="display:inline-block;  " effect="dark" :content="$t('minerTips')"
					placement="bottom">
					<i class="el-icon-question" style="font-size: 14px; margin-left: 30px;"></i>
				</el-tooltip>

			</h5>




			<p>{{$t('You_have')}}: {{lp_banance|filtersUnits}}</p>
			<div style="display: flex; justify-content: space-between;">
				<el-input v-model="stake_num"><i slot="suffix" class="el-input__icon" style="margin-right:20px">LP</i>
				</el-input>
				<el-button size="mini" type="primary" @click="max_stake_num">{{$t('MAX')}}</el-button>
			</div>
			<div class="item" style="margin:20px auto;">
				<el-button size="medium" style="width:40%" class="value" @click="stakeDialogVisible = false">
					{{$t('Cancle')}}
				</el-button>
				<el-button size="medium" style="width:40%" type="primary" @click="stake" class="label">{{$t('OK')}}
				</el-button>

			</div>
		</el-dialog>
		<!-- 赎回 -->
		<el-dialog :title="$t('Withdraw')" width="350px" :visible.sync="cancleDialogVisible">
			<h3>TIGER/USDT(LP)</h3>
			<p>{{$t('You_have_staked')}}：{{pool_stake|filtersUnits}}</p>
			<div style="display: flex; justify-content: space-between;">
				<el-input v-model="cancle_num"><i slot="suffix" class="el-input__icon" style="margin-right:20px">LP</i>
				</el-input>
				<el-button size="mini" type="primary" @click="max_cancle_num">MAX</el-button>
			</div>
			<div class="item" style="margin:20px auto;">
				<el-button size="medium" style="width:40%" class="value" @click="cancleDialogVisible = false">
					{{$t('Cancle')}}
				</el-button>
				<el-button size="medium" style="width:40%" type="primary" @click="cancle" class="label">{{$t('OK')}}
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		ethers
	} from 'ethers';
	import Big from 'big.js';
	let provider;
	import contract_address from '@/common/config/contractAddress.js';
	import MiningPool from '@/common/config/MiningPool.json';
	import IUniswapV2Pair from '@/common/config/IUniswapV2Pair.json';
	import Token from '@/common/config/ERC20.json';
	import ERC20 from '@/common/config/ERC20.json';
	import MiningV3 from '@/common/config/Mining.json';
	const MiningPoolAddress = contract_address.MiningPool;
	const TokenAddress = contract_address.LPToken;
	const PUMPAddress = contract_address.Club;
	const MiningV3Address = contract_address.Mining;
	let PoolContract;
	let LPTokenContract;
	let PUMPContract;
	let MiningContract;
	let UsdtContract;
	import axios from 'axios';
	const maxAllowance = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
	import {
		ready,
		getWallet,
		Contract
	} from '@/common/ethersConnect'
	import {
		contract as currentContract
	} from '@/store/contract.js'
	import {
		setIntervalAsync,
		clearIntervalAsync
	} from 'set-interval-async/dynamic' //定时器
	import {
		useContract
	} from '@/common/api/core.js'
	import deployments from '@/common/deployments.json';
	export default {
		data() {
			return {
				rewardRate: 0,
				day_output: '-', //日产量
				apy_rate: '-',
				hasReferrer: false, //是否绑定
				fullscreenLoading: false,
				payoutDialogVisible: false,
				stakeDialogVisible: false,
				cancleDialogVisible: false,
				usdt_total: '',
				usdt_balance: '',
				directDialogVisible: false,
				directNum: false,
				stake_num: '', //抵押数量
				cancle_num: '', //撤出储量
				pump_balance: '', //
				earned_num: '',
				lp_banance: '0', //LP余额
				pool_stake: '',
				LPTotal: '',
				lp_rate: '-',
				pump_total: '',
				is_approve: false, //是否授权
				// is_approve_usdt: false, //一键挖矿授权

				invitecode: '', //邀请编码
				regDialogVisible: false,
				regDialogLoading: false,
				// menu: {},
				//产出
				priceLast: 0,
				blockTimestampLast: '',
				blockTimestampLast_v: '',
				priceHighest: '',
				//定时器
				interval: '', //定时器
				each_lp_value: null,
				// leftTime: '' //距离时间
			};
		},
		watch: {
			address: {
				handler: async function(val) {
					if (val) {
						this.getData();
					}
				},
				immediate: true
			},
			cancle_num(val) {
				let pool_stake = ethers.utils.formatUnits(ethers.BigNumber.from(this.pool_stake));
				if (parseFloat(pool_stake) < parseFloat(val)) {
					this.cancle_num = pool_stake;
				}
			},
			stake_num(val) {
				let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.lp_banance));
				console.log(lp_banance);
				if (parseFloat(lp_banance) < parseFloat(val)) {
					this.stake_num = lp_banance;
				}
			},
			directNum(val) { //一键挖矿数量 
				let usdt_balance = ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_balance));
				console.log(val, usdt_balance);
				if (parseFloat(usdt_balance) < parseFloat(val)) {
					this.directNum = usdt_balance;
				}
			}
		},
		filters: {
			filtersUnits(num) {
				if (!num) return 0;
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			},
			filtersUnitsPrice(num) {
				if (!num) return 0;
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			}
		},

		computed: {
			address() {
				return this.$store.state.address;
			},
			provider() {
				return this.$store.state.provider;
			}
		},
		methods: {
			async getData() {
				LPTokenContract = useContract('LP');
				PoolContract = useContract('Pool');
				UsdtContract = useContract('USDT');
				let ReferContract = useContract('Refer');

				this.lp_banance = await LPTokenContract.balanceOf(this.address);
				console.log(this.lp_banance);
				this.LPTotal = await PoolContract.balanceOf(this.address);
				let total = await PoolContract.totalSupply();
				let lp_total = await LPTokenContract.totalSupply();
				//计算挖矿占比

				if (total.toString() != 0) {
					let rate = (new Big(this.LPTotal.toString())).div(new Big(total.toString()));
					this.lp_rate = rate.toString();
				}


				ReferContract.hasReferer(this.address).then(res => {
					console.log(res);
					if (res) {
						this.hasReferrer = true;
					}
				})

				let allowance = await LPTokenContract.allowance(this.address, deployments.Pool.address);
				if (!allowance.lt(this.lp_banance)) {
					this.is_approve = true; //已经授权 
				}

				this.pool_stake = this.LPTotal;

				let token0 = await LPTokenContract.token0();
				let Reserves = await LPTokenContract.getReserves();
				let lp_usdt = 0;
				if (token0.toLowerCase() === deployments.Token.address.toLowerCase()) {
					lp_usdt = Number(Reserves[1]);
				} else {
					lp_usdt = Number(Reserves[0]);
				}


				this.each_lp_value = (lp_usdt*2)/(Number(lp_total));

			},
			checkTime(i) { //将0-9的数字前面加上0，例1变为01 
				if (i < 10) {
					i = "0" + i;
				}
				return i;
			},

			async sync() {
				await MiningContract.sync();
			},
			max_stake_num() {
				let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.lp_banance));
				this.stake_num = lp_banance;
			},
			// max_directNum() {
			// 	let max = ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_balance));
			// 	this.directNum = max;
			// },
			max_cancle_num() {
				let pool_stake = ethers.utils.formatUnits(ethers.BigNumber.from(this.pool_stake));
				this.cancle_num = pool_stake;
			},
			async approve() { //授权抵押
				let that = this;
				const tx = await LPTokenContract.approve(deployments.Pool.address, maxAllowance).then(res => {
					const loading = that.$loading({
						text: '授权中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					res.wait().then(res2 => {
						loading.close();
						that.is_approve = true;
					})
				});
			},
			async approve_usdt() {
				this.fullscreenLoading = true;
				const tx = await UsdtContract.approve(deployments.Pool.address, maxAllowance);
				const receipt = await tx.wait()
				if (receipt.status == 1) {
					this.is_approve_usdt = true; //已经授权
					this.fullscreenLoading = false; //关闭加载 
				}
			},
			//抵押操作
			async stake() {
				let that = this;
				if (!this.stake_num) {
					this.$message({
						message: 'invalid quantity',
						type: 'warning'
					});
					return false;
				}
				// 代币小数位   
				console.log(this.stake_num);

				PoolContract.stake(ethers.utils.parseUnits(this.stake_num)).then(res => {
					const loading = that.$loading({
						text: '抵押中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					that.stakeDialogVisible = false;
					res.wait().then(res2 => {
						loading.close();
						that.is_approve = true;
					})
				});
			},
			// stakeUsdt() {
			// 	let that = this;
			// 	PoolContract.stakeOneClick(ethers.utils.parseUnits(this.directNum)).then(res => {
			// 		that.directDialogVisible = false;
			// 		const loading = that.$loading({
			// 			text: '一键挖矿中',
			// 			background: 'rgba(0, 0, 0, 0.7)'
			// 		});
			// 		res.wait().then(res2 => {
			// 			loading.close();
			// 		})
			// 	});
			// },
			async cancle() { //撤出炒作
				let that = this;
				if (!this.cancle_num) {
					this.$message({
						message: 'invalid quantity',
						type: 'warning'
					});
					return false;
				}
				PoolContract.withdraw(ethers.utils.parseUnits(this.cancle_num)).then(res => {
					that.cancleDialogVisible = false;
					const loading = that.$loading({
						text: '赎回中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					res.wait().then(res2 => {
						loading.close();
					})

				});
			}
		},
	};
</script>
<style lang="scss">
	@import "/src/uni.scss";

	.page {
		padding: 15px 0px;
	}

	.box-card {
		h3 {
			color: $u-type-success-dark;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}

		.item {
			display: flex;
			line-height: 30px;
			justify-content: space-between;

			.label {
				color: #666;
				font-size: 16px;
				// border: 1px solid #000;
			}

			.value {
				font-size: 14px;
				color: #333;
				font-weight: bold;
			}
		}

		.el-dialog__body {
			text-align: center;
		}
	}

	.el-card {
		border: none;
	}

	.el-message {
		border-radius: 8px;
	}

	.el-main {
		padding: 0px;
	}

	@media (min-width: 1200px) {
		.box-card {
			width: 350px;
			margin: 0 auto;
		}

		.el-main {
			padding: 20px;
		}
	}

	.el-dialog__header {
		background-color: #f4f4f4;
	}

	.el-button:focus,
	.el-button:hover {
		background-color: #122840;
	}

	.el-tooltip__popper {
		width: 80%;
	}
</style>
