<template>
	<div>
		<!-- 挖矿产出 -->
		<el-card class="box-card" shadow="never" style="margin-top: 20px;">
			<h3>{{$t('Mine')}}</h3> 
			<div class="item">
				<div class="label">{{$t('total_claimable')}}</div>
				<div class="value">{{earned_num|filtersUnits|toFloor}}</div>
			</div>
			<div class="item" style="margin-top: 20px;">
				<template v-if="hasReferrer">
					<el-button type="primary" size="medium" @click="payoutDialogVisible = true"
						style="width:50%; margin: 0px auto;">{{$t('Claim')}}</el-button>
				</template>
				<template v-if="!hasReferrer">
					<el-button type="primary" size="medium" @click="$message.error({message:'请先加入LP分紅',duration:4000})"
						style="width:50%; margin: 0px auto;">{{$t('Claim')}}</el-button>
				</template>
			</div>
		</el-card>

		<!-- 提取 -->
		<el-dialog :title="$t('Claim')" width="350px" :visible.sync="payoutDialogVisible">
			<h3>{{baseCoin}}</h3>
			<p>{{$t('total_claimable')}}：{{earned_num|filtersUnits}}</p>
			<div class="item" style="margin:20px auto;">
				<el-button size="medium" style="width:40%" class="value" @click="payoutDialogVisible = false">
					{{$t('Cancle')}}
				</el-button>
				<el-button size="medium" style="width:40%" type="primary" @click="payout" class="label">{{$t('OK')}}
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		ethers
	} from 'ethers';
	import Big from 'big.js';
	let provider;
	import contract_address from '@/common/config/contractAddress.js';
	import MiningPool from '@/common/config/MiningPool.json';
	import IUniswapV2Pair from '@/common/config/IUniswapV2Pair.json';
	import Token from '@/common/config/ERC20.json';
	import ERC20 from '@/common/config/ERC20.json';
	// import MiningV3 from '@/common/config/Mining.json';
	const MiningPoolAddress = contract_address.MiningPool;
	// const TokenAddress = contract_address.LPToken;
	const PUMPAddress = contract_address.Club;
	const MiningV3Address = contract_address.Mining;
	let MiningPoolContract;
	let LPTokenContract;
	let PUMPContract;
	let MiningContract;
	let UsdtContract;
	import axios from 'axios';
	const maxAllowance = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
	import {
		// event,
		// ready,
		getWallet,
		// hasEns
	} from '@/common/ethersConnect'
	import {
		useContract
	} from '@/common/api/core.js'
	import deployments from '@/common/deployments.json';

	export default {

		data() {
			return { 
				hasReferrer: false, //是否绑定
				fullscreenLoading: false,
				payoutDialogVisible: false,
				stakeDialogVisible: false,
				cancleDialogVisible: false, 
				usdt_balance: '',
				directDialogVisible: false,
				directNum: false,
				stake_num: '', //抵押数量
				cancle_num: '', //撤出储量 
				earned_num: '',
				lp_banance: '0', //LP余额
				pool_stake: '', 
				is_approve: false, //是否授权
				// is_approve_usdt: false, //一键挖矿授权 
				regDialogVisible: false, 
				priceLast: 0, 
			};
		},
		watch: {
			address: {
				handler: async function(val) {
					if (val) {
						this.getData();
					}
				},
				immediate: true
			},
			cancle_num(val) {
				let pool_stake = ethers.utils.formatUnits(ethers.BigNumber.from(this.pool_stake));
				if (parseFloat(pool_stake) < parseFloat(val)) {
					this.cancle_num = pool_stake;
				}
			},
			stake_num(val) {
				let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.lp_banance));
				console.log(lp_banance);
				if (parseFloat(lp_banance) < parseFloat(val)) {
					this.stake_num = lp_banance;
				}
			},
			directNum(val) { //一键挖矿数量 
				let usdt_balance = ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_balance));
				console.log(val, usdt_balance);
				if (parseFloat(usdt_balance) < parseFloat(val)) {
					this.directNum = usdt_balance;
				}
			}
		},
		filters: {
			// filtersUnits(num) {
			// 	if (!num) return 0;
			// 	return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			// },
			// filtersUnitsPrice(num) {
			// 	if (!num) return 0;
			// 	return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			// }
		},
		computed: {
			address() {
				return this.$store.state.address;
			},
			provider() {
				return this.$store.state.provider;
			}
		},
		methods: {
			async getData() {
				MiningPoolContract = useContract('Pool');
				UsdtContract = useContract('USDT'); 
				LPTokenContract = useContract('LP');
				PUMPContract = new ethers.Contract(PUMPAddress, Token, getWallet()); 
				this.earned_num = await MiningPoolContract.earned(this.address); 
				let ReferContract = useContract('Refer');
				ReferContract.hasReferer(this.address).then(res => {
					console.log(res);
					if (res) {
						this.hasReferrer = true;
					}
				}) 
			}, 
			// async mine() { //铸币
			// 	let that = this;
			// 	await MiningContract.mine().then(res => {
			// 		const loading = that.$loading({
			// 			text: '铸币中',
			// 			background: 'rgba(0, 0, 0, 0.7)'
			// 		});
			// 		res.wait().then(res2 => {
			// 			loading.close();
			// 		})
			// 	});
			// },
			async sync() {
				await MiningContract.sync();
			},
			// handleClose() { //关闭邀请界面
			// 	this.regDialogVisible = true;
			// },

			// max_stake_num() {
			// 	let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.lp_banance));
			// 	this.stake_num = lp_banance;
			// },
			// max_directNum() {
			// 	let max = ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_balance));
			// 	this.directNum = max;
			// },
			// max_cancle_num() {
			// 	let pool_stake = ethers.utils.formatUnits(ethers.BigNumber.from(this.pool_stake));
			// 	this.cancle_num = pool_stake;
			// },
			async approve() { //授权抵押
				let that = this;
				const tx = await LPTokenContract.approve(MiningPoolAddress, maxAllowance).then(res => {
					const loading = that.$loading({
						text: '授权中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					res.wait().then(res2 => {
						loading.close();
						that.is_approve = true;
					})
				});
			},
			// async approve_usdt() {
			// 	this.fullscreenLoading = true;
			// 	const tx = await UsdtContract.approve(MiningPoolAddress, maxAllowance);
			// 	const receipt = await tx.wait()
			// 	if (receipt.status == 1) {
			// 		this.is_approve_usdt = true; //已经授权
			// 		this.fullscreenLoading = false; //关闭加载 
			// 	}
			// },
			//抵押操作
			async stake() {
				let that = this;
				if (!this.stake_num) {
					this.$message({
						message: 'invalid quantity',
						type: 'warning'
					});
					return false;
				}
				// 代币小数位   
				console.log(this.stake_num);

				MiningPoolContract.stake(ethers.utils.parseUnits(this.stake_num)).then(res => {
					const loading = that.$loading({
						text: '抵押中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					that.stakeDialogVisible = false;
					res.wait().then(res2 => {
						loading.close();
						that.is_approve = true;
					})
				});
			},
			// stakeUsdt() {
			// 	let that = this;
			// 	MiningPoolContract.stakeOneClick(ethers.utils.parseUnits(this.directNum)).then(res => {
			// 		that.directDialogVisible = false;
			// 		const loading = that.$loading({
			// 			text: '一键挖矿中',
			// 			background: 'rgba(0, 0, 0, 0.7)'
			// 		});
			// 		res.wait().then(res2 => {
			// 			loading.close();
			// 		})
			// 	});
			// },
			async cancle() { //撤出炒作
				let that = this;
				if (!this.cancle_num) {
					this.$message({
						message: 'invalid quantity',
						type: 'warning'
					});
					return false;
				}
				MiningPoolContract.withdraw(ethers.utils.parseUnits(this.cancle_num)).then(res => {
					that.cancleDialogVisible = false;
					const loading = that.$loading({
						text: '赎回中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					res.wait().then(res2 => {
						loading.close();
					})

				});
			},
			payout() { //提取操作 
				let that = this;
				MiningPoolContract.getReward().then(res => {

					that.payoutDialogVisible = false;
					const loading = that.$loading({
						text: '提取中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					res.wait().then(res2 => {
						loading.close();
					})

				});
			}
		}
	};
</script>
<style lang="scss">
	@import "/src/uni.scss";

	.page {
		padding: 15px 0px;
	}

	.box-card {
		h3 {
			color: $u-type-success-dark;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}

		.item {
			display: flex;
			line-height: 30px;
			justify-content: space-between;

			.label {
				color: #666;
				font-size: 16px;
				// border: 1px solid #000;
			}

			.value {
				font-size: 14px;
				color: #333;
				font-weight: bold;
			}
		}

		.el-dialog__body {
			text-align: center;
		}
	}

	.el-card {
		border: none;
	}

	.el-message {
		border-radius: 8px;
	}

	.el-main {
		padding: 0px;
	}

	@media (min-width: 1200px) {
		.box-card {
			width: 350px;
			margin: 0 auto;
		}

		.el-main {
			padding: 20px;
		}
	}

	.el-dialog__header {
		background-color: #f4f4f4;
	}

	.el-button:focus,
	.el-button:hover {
		background-color: #122840;
	}

	.el-tooltip__popper {
		width: 80%;
	}
</style>
