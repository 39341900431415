<template>
	<div> 
		<!-- 一键挖矿 -->
		<el-card class="box-card" shadow="never" style="margin-top: 20px;">
			<h3>USDT{{$t('stakeOneClick')}} LP
			<!-- <el-tooltip effect="dark" content="一键挖矿是由智能合约帮您一次性实现“买币、增加流动性得到LP、抵押LP挖矿”这三步操作。" placement="bottom">
					<i class="el-icon-question"></i>
				</el-tooltip> -->
			</h3>
			<div class="item">
				<div class="label">{{$t('APY')}}</div>
				<div class="value">{{apy_rate|toFloor(2)}}%</div>
			</div>
			<div class="item">
				<div class="label">{{$t('wallet_usdt')}}</div>
				<div class="value">{{usdt_balance|filtersUnitsPrice|toFloor}}</div>
			</div>
			<div class="item" style="margin-top: 20px; display: flex; justify-content: center;">
				<template v-if="hasReferrer">
					<template v-if="is_approve_usdt">
						<el-button type="primary" style=" margin: 0px auto;" size="medium"
							@click="directDialogVisible = true,directNum=''">{{$t('stakeOneClick')}}
						</el-button>
					</template>
					<template v-else>
						<el-button type="primary" style="width:50%; margin: 0px auto;" size="medium"
							@click="approve_usdt()">{{$t('Approve')}}
						</el-button>
					</template>
				</template>
				<template v-if="!hasReferrer">
					<el-button size="medium" style=" margin: 0px auto;"
						@click="$message.error({message:'请先加入LP分紅',duration:4000})" type="primary">
						{{$t('stakeOneClick')}}
					</el-button> 
				</template> 
			</div>
		</el-card>
		<!-- 一键挖矿 -->
		<el-dialog :title="$t('stakeOneClick')" width="350px" :visible.sync="directDialogVisible">
			<h5 style="display: flex; align-items: center;justify-content: space-between;">USDT 
			
			<!-- <el-tooltip class="item"
					style="display:inline-block;  " effect="dark" :content="$t('minerTips')" placement="bottom">
					<i class="el-icon-question" style="font-size: 14px; margin-left: 30px;">挖矿须知</i>
				</el-tooltip> -->
			</h5>
			<p>{{$t('You_have')}}: {{usdt_balance|filtersUnits}}</p> 
			<div style="display: flex; justify-content: space-between;">
				<el-input v-model="directNum"><i slot="suffix" class="el-input__icon" style="margin-right:20px">USDT</i>
				</el-input>
				<el-button size="mini" type="primary" @click="max_directNum">{{$t('MAX')}}</el-button>
			</div>
			<div class="item" style="margin:20px auto; margin-bottom: 10px;">
				<el-button size="medium" style="width:40%" class="value" @click="directDialogVisible = false">{{$t('Cancle')}}
				</el-button>
				<el-button size="medium" style="width:40%" type="primary" @click="stakeUsdt" class="label">{{$t('OK')}}</el-button> 
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		ethers
	} from 'ethers';
	import Big from 'big.js';
	let provider;
	import contract_address from '@/common/config/contractAddress.js';
	import MiningPool from '@/common/config/MiningPool.json';
	import IUniswapV2Pair from '@/common/config/IUniswapV2Pair.json';
	import Token from '@/common/config/ERC20.json';
	import ERC20 from '@/common/config/ERC20.json';
	import MiningV3 from '@/common/config/Mining.json';
	const MiningPoolAddress = contract_address.MiningPool;
	const TokenAddress = contract_address.LPToken;
	const PUMPAddress = contract_address.Club;
	const MiningV3Address = contract_address.Mining;
	let MiningPoolContract;
	let LPTokenContract;
	let PUMPContract;
	let MiningContract;
	let UsdtContract;
	import axios from 'axios';
	const maxAllowance = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
	import deployments from '@/common/deployments.json';
	import {
		event,
		ready,
		getWallet,
		hasEns
	} from '@/common/ethersConnect';
	import {useContract} from '@/common/api/core.js'

	export default {
		data() {
			return {
				rewardRate: 0,
				day_output: '-', //日产量
				apy_rate: '-',
				hasReferrer: false, //是否绑定
				fullscreenLoading: false,
				payoutDialogVisible: false,
				stakeDialogVisible: false,
				cancleDialogVisible: false,
				usdt_total: '',
				usdt_balance: '',
				directDialogVisible: false,
				directNum: false,
				stake_num: '', //抵押数量
				cancle_num: '', //撤出储量
				pump_balance: '', //
				earned_num: '',
				lp_banance: '0', //LP余额
				pool_stake: '',
				LPTotal: '',
				lp_rate: '-',
				pump_total: '',
				is_approve: false, //是否授权
				is_approve_usdt: false, //一键挖矿授权

				invitecode: '', //邀请编码
				regDialogVisible: false,
				regDialogLoading: false,
				// menu: {},
				//产出
				priceLast: 0,
				blockTimestampLast: '',
				blockTimestampLast_v: '',
				priceHighest: '',
				//定时器
				timer: '',
				leftTime: '' //距离时间
			};
		},
		watch: {
			address: {
				handler: async function(val) {
					if (val) {
						this.getData();
					}
				},
				immediate: true
			}, 
			cancle_num(val) {
				let pool_stake = ethers.utils.formatUnits(ethers.BigNumber.from(this.pool_stake));
				if (parseFloat(pool_stake) < parseFloat(val)) {
					this.cancle_num = pool_stake;
				}
			},
			stake_num(val) {
				let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.lp_banance));
				console.log(lp_banance);
				if (parseFloat(lp_banance) < parseFloat(val)) {
					this.stake_num = lp_banance;
				}
			},
			directNum(val) { //一键挖矿数量 
				let usdt_balance = ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_balance));
				console.log(val, usdt_balance);
				if (parseFloat(usdt_balance) < parseFloat(val)) {
					this.directNum = usdt_balance;
				}
			}
		},
		filters: {
			filtersUnits(num) {
				if (!num) return 0;
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			},
			filtersUnitsPrice(num) {
				if (!num) return 0;
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			}
		},
		computed: {
			address() {
				return this.$store.state.address;
			},
			provider() {
				return this.$store.state.provider;
			}
		},
		methods: {
			async getData() { 
				UsdtContract = useContract('USDT');
				LPTokenContract = useContract('LP'); 
				let ReferContract = useContract('Refer'); 
				let PoolContract=useContract('Pool'); 
				//计算usdt
				let usdt_balance = await UsdtContract.balanceOf(this.address);
				this.usdt_balance = usdt_balance.toString(); 
				
				
				ReferContract.hasReferer(this.address).then(res => { 
					if (res) {
						this.hasReferrer = true;
					}
				}) 
				let allowanceUsdt=await UsdtContract.allowance(this.address, deployments.Pool.address) 
				if(!allowanceUsdt.lt(this.usdt_balance)) {
					this.is_approve_usdt = true; //已经授权 
				}  
				//计算年化 
				let wait_address=await LPTokenContract.token0();
				let Reserves=await LPTokenContract.getReserves(); 
				let LPtotalSupply=await LPTokenContract.totalSupply(); 
				let PooltotalSupply=await PoolContract.totalSupply();
				
				let rewardRate1=await PoolContract.rewardRate();
				let periodFinish=await PoolContract.periodFinish(); 
				let rewardRate = ethers.utils.formatUnits(ethers.BigNumber.from(rewardRate1));
				// let priceLast=await PoolContract.priceLast();
				if (periodFinish.toString() * 1000 < Date.parse(new Date())) {
					rewardRate = 0;
				}
				
				let token_total=null;
				if (wait_address.toLowerCase() === deployments.Token.address.toLowerCase()) {
					this.usdt_total = Reserves[1];
					token_total= Reserves[0];
					
				} else {
					this.usdt_total = Reserves[0];
					token_total= Reserves[1];
				}
				let usdt_total = ethers.utils.formatUnits(this.usdt_total);
				token_total= ethers.utils.formatUnits(token_total);
				// console.log(token_total);
				let priceLast1=usdt_total/token_total;
				
				// console.log(re);
				if (rewardRate) {
					console.log('抵押LP',ethers.utils.formatUnits(ethers.BigNumber.from(PooltotalSupply)));
					console.log('LP总量',ethers.utils.formatUnits(ethers.BigNumber.from(LPtotalSupply)));
					let bb= Number(LPtotalSupply)/Number(PooltotalSupply);
					console.log('矿池比重',bb);
					console.log('USDT总量',usdt_total);
					console.log('当前价格',priceLast1);
					console.log('每秒产出',rewardRate);
					
					this.apy_rate = ((rewardRate * 60 * 60 * 24 * priceLast1) / (usdt_total * 2)) *
						365 * 100*bb;
				}
				
				 
				
			}, 
			async sync() {
				await MiningContract.sync();
			},
			handleClose() { //关闭邀请界面
				this.regDialogVisible = true;
			},

			max_stake_num() {
				let lp_banance = ethers.utils.formatUnits(ethers.BigNumber.from(this.lp_banance));
				this.stake_num = lp_banance;
			},
			max_directNum() {
				let max = ethers.utils.formatUnits(ethers.BigNumber.from(this.usdt_balance));
				this.directNum = max;
			},
			max_cancle_num() {
				let pool_stake = ethers.utils.formatUnits(ethers.BigNumber.from(this.pool_stake));
				this.cancle_num = pool_stake;
			},
			async approve() { //授权抵押
				let that = this;
				const tx = await LPTokenContract.approve(MiningPoolAddress, maxAllowance).then(res => {
					const loading = that.$loading({
						text: '授权中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					res.wait().then(res2 => {
						loading.close();
						that.is_approve = true;
					})
				});
			},
			async approve_usdt() {
				this.fullscreenLoading = true;
				const tx = await UsdtContract.approve(deployments.Pool.address, maxAllowance);
				const receipt = await tx.wait()
				if (receipt.status == 1) {
					this.is_approve_usdt = true; //已经授权
					this.fullscreenLoading = false; //关闭加载 
				}
			},
			 
			stakeUsdt() {
				let that = this;
				let PoolContract=useContract('Pool'); 
				console.log(this.directNum);
				PoolContract.stakeOneClick(ethers.utils.parseUnits(this.directNum)).then(res => {
					that.directDialogVisible = false;
					const loading = that.$loading({
						text: '一键挖矿中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					res.wait().then(res2 => {
						loading.close();
					})
				});
			}
		}
	};
</script>
<style lang="scss">
	@import "/src/uni.scss";
	.page {
		padding: 15px 0px;
	}

	.box-card {
		h3 {
			color: $u-type-success-dark;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}

		.item {
			display: flex;
			line-height: 30px;
			justify-content: space-between;

			.label {
				color: #666;
				font-size: 16px;
				// border: 1px solid #000;
			}

			.value {
				font-size: 14px;
				color: #333;
				font-weight: bold;
			}
		}

		.el-dialog__body {
			text-align: center;
		}
	}

	.el-card {
		border: none;
	}

	.el-message {
		border-radius: 8px;
	}

	.el-main {
		padding: 0px;
	}

	@media (min-width: 1200px) {
		.box-card {
			width: 350px;
			margin: 0 auto;
		}

		.el-main {
			padding: 20px;
		}
	}

	.el-dialog__header {
		background-color: #f4f4f4;
	}

	.el-button:focus,
	.el-button:hover {
		background-color: #122840;
	}

	.el-tooltip__popper {
		width: 80%;
	}
</style>
