<template>
	<vue-pull-refresh :on-refresh="onRefresh">
		<div class="page">
			<el-container>
				<el-main>
					<!-- 一键挖矿 -->
					<stakeOneClick ref="stakeOneClick"></stakeOneClick>
					<!-- LP挖矿 -->
					<stake ref="stake"></stake>
					<!-- 挖矿产出+铸币 -->
					<mine ref="mine"></mine>
					  
				</el-main>
			</el-container>
		</div>
	</vue-pull-refresh>
</template>
<script>
	import stake from './components/stake';
	import mine from './components/mine';
	import stakeOneClick from './components/stakeOneClick';
	import dao from './components/dao';
	import VuePullRefresh from 'vue-pull-refresh';
	export default {
		components: {
			stake,
			stakeOneClick,
			dao,
			mine,
			'vue-pull-refresh': VuePullRefresh
		},
		methods: {
			onRefresh() {
				this.$refs.stakeOneClick.getData();
				this.$refs.stake.getData();
				this.$refs.mine.getData();
			},
		}
	};
</script>
<style lang="scss">
	@import "/src/uni.scss";

	.page {
		padding: 15px 0px;
	}

	.box-card {
		h3 {
			color: $u-type-success-dark;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}

		.item {
			display: flex;
			line-height: 30px;
			justify-content: space-between;

			.label {
				color: #666;
				font-size: 16px;
				// border: 1px solid #000;
			}

			.value {
				font-size: 14px;
				color: #333;
				font-weight: bold;
			}
		}

		.el-dialog__body {
			text-align: center;
		}
	}

	.el-card {
		border: none;
	}

	.el-message {
		border-radius: 8px;
	}

	.el-main {
		padding: 0px;
	}

	@media (min-width: 1200px) {
		.box-card {
			width: 350px;
			margin: 0 auto;
		}

		.el-main {
			padding: 20px;
		}
	}

	.el-dialog__header {
		background-color: #f4f4f4;
	}

	.el-button:focus,
	.el-button:hover {
		background-color: #122840;
	}

	.el-tooltip__popper {
		width: 80%;
	}
</style>
