<template>
	<div class="box-card" shadow="never" style="margin-top: 20px;">
		<el-card>
			<h3>{{$t('DAO_Share')}}</h3>
			<div class="item">
				<div class="label">{{$t('DAO_LP_Quantity')}}</div>
				<div class="value"> {{user.totalRefereesStaked|filtersUnits|toFloor}} LP</div>
			</div>
			<div class="item">
				<div class="label">{{$t('DAO_LP_Reward')}}</div>
				<div class="value"> {{total_num|toFloor}} LP</div>
			</div>
			<div class="item bottom-btn">
				<el-button :disabled="!total_num" @click="cancleDialogVisible=true" style="width:50%; margin: 0px auto;" size="medium" type="primary">{{$t('Claim')}}
				</el-button>
			</div>
		</el-card>
		
		<el-dialog :title="$t('Claim')" width="350px" :visible.sync="cancleDialogVisible">
			<h3>{{$t('DAO_LP_Reward')}}</h3>
			<p>{{total_num|toFloor}} LP</p> 
			<div class="item" style="margin:20px auto;">
				<el-button size="medium" style="width:40%" class="value" @click="cancleDialogVisible = false">
					{{$t('Cancle')}}
				</el-button>
				<el-button size="medium" style="width:40%" type="primary" @click="withdraw" class="label">{{$t('OK')}}
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		ethers
	} from 'ethers';
	import axios from "axios";
	import {
		useContract
	} from '@/common/api/core.js'
	import {
		contract,
		getters
	} from '@/store/contract.js'
	import deployments from '@/common/deployments.json';
	import {
		priceLast
	} from '@/common/api/swap.js'
	import {
		getWallet,
		getProvider
	} from '@/common/ethersConnect'
	//数据源配置
	axios.defaults.baseURL = 'https://api.tigertoken.xyz/';
	// axios.defaults.baseURL = 'http://tigertoken.site/';
	export default {
		data() {
			return {
				cancleDialogVisible:false,
				total_num: 0,
				user: {
					totalRefereesStaked: 0
				},
			}
		},
		async created() {

			this.$watch(() => contract.default_account, val => {
				if (val) {
					// console.log(val);
					this.mounted()
				}
			}, {
				immediate: true,
			})

		},
		computed: {
			address() {
				return this.$store.state.address;
			}
		},
		filters: {
			filtersUnits(num) {
				if (!num) return 0;
				//将wei的数量格式化表示单位数量的十进制字符串
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			}
		},
		methods: {
			async mounted() {
				let LPTokenContract = useContract('LP');
				let token0 = await LPTokenContract.token0();
				let Reserves = await LPTokenContract.getReserves();
				let lp_total = await LPTokenContract.totalSupply();
				let lp_usdt = 0;
				if (token0.toLowerCase() === deployments.Token.address.toLowerCase()) {
					lp_usdt = Number(Reserves[1]);
				} else {
					lp_usdt = Number(Reserves[0]);
				}
				let lp_price = (lp_usdt * 2) / (Number(lp_total));
				let coin_price = await priceLast();
				//后端获取统计数据
				console.log(this.address); //*老虎比价格/lp价格
				axios.get('biz/hql/award_num', {
					params: {
						address: this.address,
						price: lp_price
					}
				}).then(res => {
					let data = res.data.data.data;
					// console.log(res.data.data);
					this.total_num = data.total_num * coin_price / lp_price;
					if (data.user) {
						this.user = data.user;
					}
				})
			}, 
			async withdraw() {
				//签名 
				let wallet = getWallet();
				let timestamp = parseInt(new Date() / 1000);
				let result = await wallet.signMessage(timestamp.toString());
				let sign = {
					address: this.address,
					timestamp: timestamp,
					signature: result
				};
				//申请提现
				axios.post('biz/hql/withdraw', sign).then(res => {
					this.$message({
						message: '提取成功，请等待链上确认',
						type: 'success'
					});
					this.cancleDialogVisible=false;
					this.mounted();
				})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	@import "/src/uni.scss";

	.bottom-btn {
		margin: 20px auto;
		margin-bottom: 10px;
		// display: flex;
		// justify-content: center;
		// align-items: center !important;
		// border: 1px solid #000;
	}

	.page {
		padding: 15px 0px;
	}

	.box-card {
		h3 {
			color: $u-type-success-dark;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}

		.item {
			display: flex;
			line-height: 30px;
			justify-content: space-between;

			.label {
				color: #666;
				font-size: 16px;
				// border: 1px solid #000;
			}

			.value {
				font-size: 14px;
				color: #333;
				font-weight: bold;
			}
		}

		.el-dialog__body {
			text-align: center;
		}
	}

	.el-card {
		border: none;
	}

	.el-message {
		border-radius: 8px;
	}

	.el-main {
		padding: 0px;
	}

	@media (min-width: 1200px) {
		.box-card {
			width: 350px;
			margin: 0 auto;
		}

		.el-main {
			padding: 20px;
		}
	}

	.el-dialog__header {
		background-color: #f4f4f4;
	}

	.el-button:focus,
	.el-button:hover {
		background-color: #122840;
	}

	.el-tooltip__popper {
		width: 80%;
	}
</style>
