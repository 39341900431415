import {
	useContract
} from '@/common/api/core.js'
import deployments from '@/common/deployments.json';
export async function priceLast() { //最近价格叫你算
	let price=0;
	let LPTokenContract = useContract('LP');
	let wait_address = await LPTokenContract.token0();
	let Reserves = await LPTokenContract.getReserves();
	if (wait_address.toLowerCase() === deployments.Token.address.toLowerCase()) {
		price=Number(Reserves[1]) / Number(Reserves[0]);  
	} else {
		price=Number(Reserves[0]) / Number(Reserves[1]);
	}
	return price.toFixed(2)
}
